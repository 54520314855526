import 'jquery';
import 'bootstrap';
import './directives/share-url';
import './directives/radio-player';
import './tracking/tracking-directive';
import './elements/most-read-news';
import './elements/latest-news-list';
import './elements/insights-toggle-bar';
import './elements/insights-bar';
import './elements/story-teaser-slider';
import './elements/nj-newsletter-form';
import './elements/reels-slider';
import TrackingService from './tracking/tracking-service';
import ComponentManager from './services/component-manager';
import LayoutMenu from './components/layout-menu';
import CookieConsent from './components/cookie-consent';
import GoogleAdManager from './advertisements/google-ad-manager';
import AdUnitMananger from './advertisements/ad-unit-manager';
import FormValidation from './components/form-validation';
import ActivityBanner from './components/activity-banner';
import LocalSitesWidget from './components/local-sites-widget';
import TeaserImageSliderComponent from './components/teaser-image-slider';
import CurrentNewsBar from './components/current-news-bar';
import ImportantInfoBar from './components/important-info-bar';
import Insights from './components/insights';

console.debug('Initializing Nordjyske...');

ComponentManager.registerComponents([
  LayoutMenu,
  CookieConsent,
  GoogleAdManager,
  AdUnitMananger,
  FormValidation,
  ActivityBanner,
  LocalSitesWidget,
  TeaserImageSliderComponent,
  CurrentNewsBar,
  ImportantInfoBar,
  Insights
]);

export {
  TrackingService as Tracking
};
