import IComponent from './IComponent';

const register = function (): void {
  // Fetch all the forms we want to apply custom Bootstrap validation styles to
  const forms = document.querySelectorAll('.needs-validation');

  // Loop over them and prevent submission
  Array.from(forms).forEach(form => {
    form.addEventListener('submit', event => {
      if (!(<HTMLFormElement>form).checkValidity()) {
        event.preventDefault();
        event.stopPropagation();
      }

      form.classList.add('was-validated');
    }, false);
  });
};

const formValidationComponent: IComponent = {
  name: 'Form Validation',
  register: register
};

export default formValidationComponent;
