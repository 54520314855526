/**
 * Process tracking queue.
 */
const processTrackingQueue = function () {
  console.debug('Tracking provider loaded: GoogleTagManager');
  console.debug('Processing queue: GoogleTagManager');

  // first capture push function, so new calls are executed immediately
  window.trackingQueue.GoogleTagManager.push = function (callbackObj) {
    if (typeof (callbackObj) === 'function') {
      callbackObj();
    }
    return 0;
  };

  // then empty the existing queue of callbacks already added
  window.trackingQueue.GoogleTagManager.forEach(function (v) {
    window.trackingQueue.GoogleTagManager.push(v);
  });
};

/**
 *
 */
const register = function () {
  console.debug('Registering tracking provider: GoogleTagManager');

  if (typeof gtag !== 'undefined') {
    processTrackingQueue();
  } else {
    window.addEventListener('gtm_loaded', () => {
      processTrackingQueue();
    });
  }
};

/**
 *
 * @param trackerId
 */
const initGtm = function (trackerId: string) {
  window.trackingQueue.GoogleTagManager.push(() => {
    gtag('js', new Date());
    gtag('config', `'${trackerId}'`);

    if (window.currentUser.UserId) {
      gtag('config', `'${trackerId}'`, {
        'user_id': window.currentUser.UserId
      });
      gtag('set', 'user_properties', {
        'dnmh_user_id': window.currentUser.UserId
      });
    }

    if (window.user_id) {
      gtag('config', 'MEASUREMENT_ID', {
        'user id': window.user_id
      });
    }
  });
};

/**
 *
 *  Sets all consent options in GTM to granted.
 */
const gtmGrantAllConsent = function() {
  window.trackingQueue.GoogleTagManager.push(() => {
    gtag('consent', 'update', {
      'ad_storage': 'granted',
      'ad_user_data': 'granted',
      'ad_personalization': 'granted',
      'analytics_storage': 'granted',
      'functionality_storage': 'granted',
      'personalization_storage': 'granted',
      'security_storage': 'granted'
    });
  })
}

/**
 *
 * Sets all consent options in GTM to denied.
 */
const gtmRevokeAllConsent = function() {
  window.trackingQueue.GoogleTagManager.push(() => {
    gtag('consent', 'update', {
      'ad_storage': 'denied',
      'ad_user_data': 'denied',
      'ad_personalization': 'denied',
      'analytics_storage': 'denied',
      'functionality_storage': 'denied',
      'personalization_storage': 'denied',
      'security_storage': 'denied'
    });
  })
}

export default {
  gtmGrantAllConsent,
  gtmRevokeAllConsent,
  register,
  initGtm,
};
