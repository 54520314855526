import { auto } from '@popperjs/core';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

class HTMLStoryTeaserSliderElement extends HTMLElement {
  connectedCallback() {
    const swiper: HTMLElement = this.querySelector('.story-teaser-slider-swiper');
    const nextBtn: HTMLElement = this.querySelector('.story-teaser-slider__swiper-button-next');
    const prevBtn: HTMLElement = this.querySelector('.story-teaser-slider__swiper-button-prev');

    new Swiper(swiper, {
      modules: [Navigation],
      slidesPerView: auto,
      spaceBetween: '16px',
      navigation: {
        nextEl: nextBtn,
        prevEl: prevBtn,
      },
    });
  }
}

// Define the new element
customElements.define('story-teaser-slider', HTMLStoryTeaserSliderElement);
