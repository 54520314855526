import { auto } from '@popperjs/core';
import IComponent from './IComponent';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

const register = function (): void {

  document.addEventListener('DOMContentLoaded', () => {
    const swipers = document.querySelectorAll('.activity-banner-swiper');

    Array.from(swipers).forEach((swiper: HTMLElement) => {
      const nextBtn: HTMLElement = swiper.querySelector('.activity-banner__swiper-button-next');
      const prevBtn: HTMLElement = swiper.querySelector('.activity-banner__swiper-button-prev');

      new Swiper(swiper, {
        modules: [Navigation],
        slidesPerView: auto,
        spaceBetween: '16px',
        navigation: {
          nextEl: nextBtn,
          prevEl: prevBtn,
        },
      });

    });
  });
};

const activityBannerComponenet: IComponent = {
  name: 'Activity Banner',
  register: register
};

export default activityBannerComponenet;
