/**
 * Process tracking queue.
 */
const processTrackingQueue = function (): void {
  if (!window.trackingScriptsEnabled.LinkedIn) {
    console.debug('LinkedIn tracking scripts not enabled - do not process tracking queue');
    return;
  }

  console.debug('Processing queue: LinkedIn');

  // first capture push function, so new calls are executed immediately
  window.trackingQueue.LinkedIn.push = function (callbackObj) {
    if (typeof (callbackObj) === 'function') {
      callbackObj();
    }
    return 0;
  };

  // then empty the existing queue of callbacks already added
  window.trackingQueue.LinkedIn.forEach(function (v) {
    window.trackingQueue.LinkedIn.push(v);
  });
};

/**
 *
 */
const register = function () {
  console.debug('Registering tracking provider: LinkedIn');

  window.addEventListener('li_loaded', () => {
    console.debug('Tracking provider loaded: LinkedIn');
    processTrackingQueue();
  });
};

/**
 * Encapsulates all LinkedIn tracking functionality.
 */
export default {
  register,
};
