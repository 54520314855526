/**
 * Opens a radio player
 * @param url the player url
 * @param popup opens the player in a new popup window if true
 */
const openRadioPlayer = function (url: string, popup: boolean): void {
  if (!url) {
    console.error('Can not open radio player', url);
    return;
  }

  if (popup) {
    const playerSettings = [
      'width=375',
      'height=615',
      'toolbar=no',
      'personalbar=no',
      'location=no',
      'directories=no',
      'statusbar=no',
      'menubar=no',
      'status=no',
      'resizable=no',
      'scrollbars=1',
      'left=60',
      'screenX=60',
      'top=100',
      'screenY=100'
    ];

    const playerWindow = window.open(url, 'player', playerSettings.join(','));
    playerWindow.focus();
  } else {
    location.href = url;
  }
};

export {
  openRadioPlayer
};
