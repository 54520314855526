/**
 * Sends a hit to Google Analytics.
 * @param trackerName Name of the tracker object.
 * @param category Specifies the event category. Must not be empty.
 * @param action Specifies the event action. Must not be empty.
 * @param label Specifies the event label.
 * @param value Specifies the event value. Values must be non-negative.
 * @param page Specifies the page url of which this event should propagate to. Must be without host. etc. /nyheder/page/{id}
 * @param hitCallback If a callback is needed when the event has been pushed; send the event here.
 * @param nonInteractive nonInteractive mode
 */
const sendEvent = function (trackerName: string, category: string, action: string, label: string, value?: number, page?: string, hitCallback?: any, nonInteractive?: boolean): void {
  // Value has to be a non-negative integer, so we default to 0
  if (!value || value === undefined) {
    value = 0;
  }

  // What are we tracking? :O
  const command: string = (trackerName !== '' && trackerName !== 'send') ? trackerName + '.send' : 'send';

  // Define object to send as event
  const objectToSend: any = {
    'hitType': 'event',
    'eventCategory': category,
    'eventAction': action,
    'eventLabel': label,
    'eventValue': value
  };

  // If a page is specified; add it.
  if (page) {
    objectToSend['page'] = page;
  }

  // Add hitCallback info
  if (hitCallback && typeof hitCallback === 'function') {
    objectToSend['hitCallback'] = hitCallback;
  }

  // If this is set add to the object
  if (nonInteractive) {
    objectToSend['nonInteraction'] = true;
  }

  window.trackingQueue.GoogleAnalytics.push(() => {
    ga(command, objectToSend);
  });
};

/**
 * Process tracking queue.
 */
const processTrackingQueue = function () {
  console.debug('Tracking provider loaded: GoogleAnalytics');

  if (!window.trackingScriptsEnabled.Google) {
    console.debug('GoogleAnalytics tracking scripts not enabled - do not process tracking queue');
    return;
  }

  console.debug('Processing queue: GoogleAnalytics');

  // first capture push function, so new calls are executed immediately
  window.trackingQueue.GoogleAnalytics.push = function (callbackObj) {
    if (typeof (callbackObj) === 'function') {
      callbackObj();
    }
    return 0;
  };

  // then empty the existing queue of callbacks already added
  window.trackingQueue.GoogleAnalytics.forEach(function (v) {
    window.trackingQueue.GoogleAnalytics.push(v);
  });
};

/**
 *
 */
const register = function () {
  console.debug('Registering tracking provider: Google Analytics');

  if (typeof ga !== 'undefined') {
    processTrackingQueue();
  }
  else {
    window.addEventListener('ga_loaded', () => {
      processTrackingQueue();
    });
  }
};

/**
 *
 * @param trackerId
 * @param options
 */
const createTracker = function (trackerId: string, options: any) {
  window.trackingQueue.GoogleAnalytics.push(() => {
    ga('create', trackerId, options);
  });
};

/**
 *
 * @param trackerName
 * @param requiredName
 * @param requiredScript
 */
const setTrackerRequirement = function (trackerName: string, requiredName: string, requiredScript?: string) {
  const command = trackerName ? `${trackerName}.require` : 'require';

  if (requiredScript) {
    window.trackingQueue.GoogleAnalytics.push(() => {
      ga(`'${command}'`, `'${requiredName}'`, `'${requiredScript}'`);
    });
  }
  else {
    window.trackingQueue.GoogleAnalytics.push(() => {
      ga(`'${command}'`, `'${requiredName}'`);
    });
  }
};

/**
 *
 * @param trackerName
 * @param domains
 */
const setLinkedDomains = function (trackerName: string, domains: Array<string>) {
  const command = trackerName ? `${trackerName}.linker:autoLink` : 'linker:autoLink';

  window.trackingQueue.GoogleAnalytics.push(() => {
    ga(`'${command}'`, domains);
  });
};

/**
 *
 * @param dimensionName
 * @param dimensionValue
 */
const setTrackerDimension = function (dimensionName: string, dimensionValue: string) {
  window.trackingQueue.GoogleAnalytics.push(() => {
    ga('set', `'${dimensionName}'`, dimensionValue);
  });
};

/**
 *
 * @param url
 */
const updateCurrentPageForTrackers = function (url: string) {
  window.trackingQueue.GoogleAnalytics.push(() => {
    const trackers: UniversalAnalytics.Tracker[] = ga.getAll();

    trackers.forEach((tracker: UniversalAnalytics.Tracker): void => {
      tracker.set('page', url);
    });
  });
};

/**
 *
 * @param trackerName
 */
const sendPageView = function (trackerName: string) {
  const command = trackerName ? `${trackerName}.send` : 'send';

  window.trackingQueue.GoogleAnalytics.push(() => {
    ga(command, 'pageview');
  });
};

/**
 *
 */
const sendPageViewAllTrackers = function () {
  window.trackingQueue.GoogleAnalytics.push(() => {
    const trackers: UniversalAnalytics.Tracker[] = ga.getAll();

    trackers.forEach((tracker: UniversalAnalytics.Tracker): void => {
      tracker.send('pageview');
    });
  });
};

/**
 *
 * @param storyId
 * @param trackingLocation
 */
const sendNativePageView = function (storyId: string, trackingLocation: string) {
  window.trackingQueue.GoogleAnalytics.push(() => {
    ga('send', 'event', 'Native', trackingLocation, storyId, undefined, { nonInteraction: true });
  });
};

/**
 *
 * @param action
 */
const sendMyPageAction = function (action: string) {
  sendEvent('', 'min_side', action, '', 0);
};

/**
 *
 * @param category
 * @param label
 * @param nonInteractive
 */
const sendPageViewWithCategory = function (category: string, label: string, nonInteractive?: boolean | true) {
  sendEvent('send', category, 'pageview', label, undefined, undefined, undefined, nonInteractive);
};

/**
 *
 * @param price
 */
const sendPaywallProductPurchasedForAllTrackers = function (price?: number) {
  sendEvent('alle_samlet', 'paywall', 'koeb_gennemført', 'CCE', price);
};

/**
 *
 * @param page
 */
const sendConversionForPage = function (page: string) {
  sendEvent('send', 'Konvertering', 'click', 'Antal konverteringer', undefined, page, undefined);
};

/**
 *
 * @param category
 * @param label
 */
const sendLinkClickedEvent = function (category: string, label: string) {
  sendEvent('send', category, 'click', label);
};

export default {
  register,
  createTracker,
  setTrackerRequirement,
  setLinkedDomains,
  setTrackerDimension,
  updateCurrentPageForTrackers,
  sendPageView,
  sendPageViewAllTrackers,
  sendNativePageView,
  sendMyPageAction,
  sendPageViewWithCategory,
  sendPaywallProductPurchasedForAllTrackers,
  sendConversionForPage,
  sendLinkClickedEvent,
};
