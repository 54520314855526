import { RX_NUMBER } from '../constants/regex';

export const toType = (value: any) => typeof value;

export const toRawType = (value: any) => Object.prototype.toString.call(value).slice(8, -1);

export const toRawTypeLC = (value: any) => toRawType(value).toLowerCase();

export const isUndefined = (value: any) => value === undefined;

export const isNull = (value: null) => value === null;

export const isEmptyString = (value: string) => value === '';

export const isUndefinedOrNull = (value: any) => isUndefined(value) || isNull(value);

export const isUndefinedOrNullOrEmpty = (value: any) => isUndefinedOrNull(value) || isEmptyString(value);

export const isFunction = (value: any) => toType(value) === 'function';

export const isBoolean = (value: any) => toType(value) === 'boolean';

export const isString = (value: any) => toType(value) === 'string';

export const isNumber = (value: any) => toType(value) === 'number';

export const isNumeric = (value: any) => RX_NUMBER.test(String(value));

export const isPrimitive = (value: any) => isBoolean(value) || isString(value) || isNumber(value);

export const isArray = (value: any) => Array.isArray(value);

// Quick object check
// This is primarily used to tell Objects from primitive values
// when we know the value is a JSON-compliant type
// Note object could be a complex type like array, Date, etc.
export const isObject = (obj: null) => obj !== null && typeof obj === 'object';

// Strict object type check
// Only returns true for plain JavaScript objects
export const isPlainObject = (obj: any) => Object.prototype.toString.call(obj) === '[object Object]';

export const isDate = (value: any) => value instanceof Date;

export const isEvent = (value: any) => value instanceof Event;

export const isRegExp = (value: any) => toRawType(value) === 'RegExp';

export const isPromise = (value: Promise<any>) =>
  !isUndefinedOrNull(value) && isFunction(value.then) && isFunction(value.catch);
