const createElementFromHTML = function (htmlString: string): Element | null {
  if (!htmlString) {
    return null;
  }

  const div = document.createElement('div');
  div.innerHTML = htmlString.trim();

  return div.firstElementChild;
};

export {
  createElementFromHTML
};
