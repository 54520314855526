import NewsletterSettingsApi, { SubscribeRequest } from '../apis/newsletter-settings-api';

class HTMLNewsletterFormElement extends HTMLElement {
  connectedCallback() {
    const form = this.getForm();
    form.addEventListener('submit', this.submitForm);
  }

  disconnectedCallback() {
    const form = this.getForm();
    form.removeEventListener('submit', this.submitForm);
  }

  private async submitForm(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    const form = event.target as HTMLFormElement;
    const fieldset = form.querySelector('fieldset') as HTMLFieldSetElement;
    const successfulBlock = form.querySelector('[data-nj-subscribed="true"]') as HTMLElement;
    const unsuccessfulBlock = form.querySelector('[data-nj-subscribed="false"]') as HTMLElement;

    const formData = new FormData(form);

    const request: SubscribeRequest = {
      'Email': formData.get('Email').toString(),
      'FirstName': formData.get('FirstName').toString(),
      'LastName': formData.get('LastName').toString(),
      'FullName': formData.get('FullName').toString(),
      'Newsletter': formData.get('Newsletter').toString(),
    };

    fieldset.disabled = true;

    const response = NewsletterSettingsApi.subscribe(request)
      .then((response) => {
        if (response.ok) {
          successfulBlock.style.display = 'block';
          fieldset.style.display = 'none';
        } else {
          throw new Error('Something went wrong');
        }
      })
      .catch((error) => {
        console.error('catch', error);
        unsuccessfulBlock.style.display = 'block';
        fieldset.style.display = 'none';
      })
      .finally(() => {
        fieldset.disabled = false;
      });

    await response;
  }

  private getForm(): HTMLFormElement {
    return this.querySelector('form') as HTMLFormElement;
  }
}

// Define the new element
customElements.define('nj-newsletter-form', HTMLNewsletterFormElement);
