import IComponent from './IComponent';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

class TeaserImageSliderElement {
  private _root: HTMLElement;
  private _nextButton: HTMLElement;
  private _prevButton: HTMLElement;
  private _link: HTMLElement;
  private _muteButton: HTMLButtonElement;
  private _progressBar: HTMLElement;
  private _currentVideo: HTMLVideoElement;

  private _swiper: Swiper;
  private _isMuted: boolean;

  init(element: HTMLElement) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const self = this;
      this._root = element;
      this._muteButton = element.querySelector('.teaser-image-slider__mute') as HTMLButtonElement;
      this._muteButton.onclick = function () {
          self.setMute(!self._isMuted);
      };

      if (element.classList.contains('swiper-container')) {
          this._nextButton = element.querySelector('.teaser-image-slider__button--next') as HTMLElement;
          this._prevButton = element.querySelector('.teaser-image-slider__button--prev') as HTMLElement;
          this._link = element.querySelector('.teaser-image-slider__link') as HTMLElement;
          this._progressBar = element.querySelector('.teaser-image-slider__progress') as HTMLElement;

          this._swiper = new Swiper(element, {
            modules : [Navigation],
              spaceBetween: 0,
              mousewheel: {
                  forceToAxis: true
              },
              navigation: {
                  nextEl: this._nextButton,
                  prevEl: this._prevButton,
              }
          });

          this._swiper.on('slideChange', function () {
              self.onSlideChange();
          });

          this._swiper.on('setTranslate', function (_: any, translate: number) {
              self.onMoveSlide(translate);
          });

          this._swiper.on('transitionEnd', function () {
              self.onNewSlide(self.currentSlide());
          });
      }
      // Autopaly videos need to be muted first before calling play.
      this.setMute(true);
      this.onNewSlide(element.querySelector('.swiper-slide') as HTMLElement);
  }

  // When the slide is being dragged
  onMoveSlide(translate: number) {
      const fraction = translate / -(this.swiperGetContentWidth() - this.width()) * 100;
      this._progressBar.style.width = fraction + '%';

      if (this._currentVideo != null) {
          this._currentVideo.pause();
          this._currentVideo = null;
      }
  }

  // When the slide changed and animating.
  onSlideChange() {
      const fraction = this._swiper.realIndex / (this.slideCount() - 1) * 100;
      this._progressBar.style.width = fraction + '%';

      const isLast = this._swiper.realIndex == this.slideCount() - 1;
      this._prevButton.style.visibility = this._swiper.realIndex == 0 ? 'hidden' : 'visible';
      this._nextButton.style.visibility = isLast ? 'hidden' : 'visible';
      this._link.style.visibility = isLast ? 'visible' : 'hidden';

      if (this._currentVideo != null) {
          this._currentVideo.pause();
          this._currentVideo = null;
      }
  }

  // When slide stopped moving.
  onNewSlide(slide: HTMLElement) {
      const video = slide.getElementsByTagName('video');

      if (video.length < 1) {
          if (this._muteButton != null) { this._muteButton.style.visibility = 'hidden'; }
          return;
      }
      if (this._muteButton != null) { this._muteButton.style.visibility = 'visible'; }

      this._currentVideo = video[0];
      this._currentVideo.play();

      video[0].onended = function () {
          video[0].play();
      };
  }

  // Current slide
  currentSlide(): HTMLElement {
      return this._swiper.slides[this._swiper.realIndex] as HTMLElement;
  }

  // Slides
  slideCount(): number {
      return this._swiper.slides.length;
  }

  // All slides width
  swiperGetContentWidth(): number {
      return this.slideCount() * this.width();
  }

  // The width
  width(): number {
      return this._swiper.width;
  }

  // Mutes / unmutes all videos
  setMute(mute: boolean) {
      this._isMuted = mute;

      const videoElements = this._root.getElementsByTagName('video');

      for (let i = 0; i < videoElements.length; i++) {
          const video = videoElements[i] as HTMLVideoElement;
          video.muted = mute;
      }

      if (mute) {
          (this._muteButton.firstElementChild as HTMLImageElement).src = '/images/icons/Video/mute_icon.svg';
      }
      else {
          (this._muteButton.firstElementChild as HTMLImageElement).src = '/images/icons/Video/unmute_icon.svg';
      }
  }
}

const register = function (): void {
  document.addEventListener('DOMContentLoaded', () => {
    const sliderElements = document.querySelectorAll('.teaser-image-slider');

    Array.from(sliderElements).forEach((slider : HTMLElement) => {
      const teaserSlider = new TeaserImageSliderElement();
      teaserSlider.init(slider);
    });
  });
};

const teaserImageSliderComponent: IComponent = {
  name: 'TeaserImageSlider',
  register: register
};

export default teaserImageSliderComponent;
