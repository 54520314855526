import { Toast } from 'bootstrap';
import { createElementFromHTML } from '../utils/element';
import { ThemeColor } from '../constants/components';

const toastContainerId = 'toastContainer';

/**
 * Generates a toast message.
 * @param message The body message of the toast.
 * @param title The title of the toast.
 * @param color The color of the toast.
 * @returns The toast element.
 */
function makeToast(message: string, title?: string, color?: ThemeColor): Element {
  let html: string;

  const toastColor = color ? ` text-bg-${color.toString().toLowerCase()}` : '';

  if (title && message) {
    html = `
      <div class="toast${toastColor}" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">${title}</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">
          ${message}
        </div>
      </div>`;
  } else if (title && !message) {
    html = `
      <div class="toast${toastColor}" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">${title}</strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
      </div>`;
  } else {
    html = `
      <div class="toast${toastColor} align-items-center" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="d-flex">
          <div class="toast-body">
            ${message}
          </div>
          <button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
      </div>`;
  }

  return createElementFromHTML(html);
}

/**
 * Shows a toast message.
 * @param message The body message of the toast.
 * @param title The title of the toast.
 * @param color The color of the toast.
 */
const showToast = function (message: string, title?: string, color?: ThemeColor): Toast {
  const toastElement = makeToast(message, title, color);

  const toastContainer = document.getElementById(toastContainerId);
  toastContainer.appendChild(toastElement);

  const toast = new Toast(toastElement);
  toast.show();

  return toast;
};

export {
  showToast
};
