import GoogleAnalytics from './googleanalytics-provider';
import GoogleAnalytics4 from './googleanalytics4-provider';
import GoogleTagManager from './googletag-provider';
import Facebook from './facebook-provider';
import LinkedIn from './linkedin-provider';
import Gemius from './gemius-provider';

window.trackingQueue = {
  GoogleAnalytics: new Array<() => void>,
  GoogleAnalytics4: new Array<() => void>,
  GoogleTagManager: new Array<() => void>,
  Facebook: new Array<() => void>,
  LinkedIn: new Array<() => void>,
  Gemius: new Array<() => void>
};

GoogleTagManager.register();
GoogleAnalytics.register();
GoogleAnalytics4.register();
Facebook.register();
LinkedIn.register();
Gemius.register();

export default {
  GoogleAnalytics,
  GoogleAnalytics4,
  GoogleTagManager,
  Facebook,
  LinkedIn,
  Gemius
};
