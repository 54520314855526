class HTMLLatestNewsListElement extends HTMLElement {
  constructor() {
    // Always call super first in constructor
    super();

    this.requestInit = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: new Headers(),
    };
  }

  private requestInit: RequestInit;

  /** Max number of posts. */
  get limit(): number {
    const itemCount = parseInt(this.getAttribute('limit'));
    return isNaN(itemCount) ? 7 : itemCount;
  }

  private async fetchNews() {
    const searchParams = new URLSearchParams({ count: this.limit.toString() });

    return fetch('/api/Search/GetLatestNews?' + searchParams, this.requestInit)
      .then(data => data.text())
      .then((html: string) => this.innerHTML = html)
      .catch((error) => console.error('Could not fetch most read news:', error));
  }

  connectedCallback() {
    this.fetchNews();
  }
}

// Define the new element
customElements.define('latest-news-list', HTMLLatestNewsListElement);
