import * as $ from 'jquery';
import { openRadioPlayer } from '../services/radio-service';

const radioPlayerAttr = 'radio-player-url';
const radioPlayerPopupAttr = 'radio-player-popup';

$(document.body)
  .on('click', `[data-${radioPlayerAttr}]`, (event: JQuery.ClickEvent) => {
    event.preventDefault();

    const target = $(event.currentTarget);
    const radioPlayerUrl = target.data(radioPlayerAttr);
    const radioPlayerPopup = target.data(radioPlayerPopupAttr)?.toString() === 'true';

    openRadioPlayer(radioPlayerUrl, radioPlayerPopup);
  });
