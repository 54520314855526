/**
 * Fields to subscribe to a newsletter
 * @field FullName is used for bot prevention and should be empty
 */
export interface SubscribeRequest {
  FirstName: string;
  LastName: string;
  FullName: string;
  Email: string;
  Newsletter: string;
}

/**
 * Fields to subscribe to the daily newsletter
 * Inherits from SubscribeRequest
 */
export interface SubscribeDailyRequest extends SubscribeRequest {
  Areas: string[];
  Timeslots: string[];
  Terms: string;
}

/**
 * 
 * @param request Data about who to subscribe and to which newsltter
 * @returns A promise with info of how the request was handled
 */
const subscribe = function (request: SubscribeRequest): Promise<Response> {
  const url = '/api/NewsletterSettings/subscribe';

  const formData = new FormData();
  formData.append('Email', request.Email);
  formData.append('FirstName', request.FirstName);
  formData.append('LastName', request.LastName);
  formData.append('FullName', request.FullName);
  formData.append('Newsletter', request.Newsletter);

  const requestInit: RequestInit = {
    method: 'POST',
    body: formData,
  };

  return fetch(url, requestInit);
};

/**
 * 
 * @param request Info about who to unsubscribe and from which newsletters
 * @returns A promise with info of how the request was handled
 */
const unsubscribe = function(request: SubscribeRequest): Promise<Response> {
  const url = '/api/NewsletterSettings/unsubscribe';

  const requestInit: RequestInit = {
    method: 'POST',
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(request),
  };

  return fetch(url, requestInit);
};

/**
 * 
 * @param request Info about which daily newsletters to subscribe to and who is subscribing
 * @returns A promise with info of how the request was handled
 */
const subscribeDailyNewsletter = function (request: SubscribeDailyRequest): Promise<Response> {
  const url = '/NewsletterSettings/update';

  const formData = new FormData();
  formData.append('Email', request.Email);
  formData.append('FirstName', request.FirstName);
  formData.append('LastName', request.LastName);
  formData.append('FullName', request.FullName);
  formData.append('Terms', request.Terms);

  request.Areas.forEach(area => {
    formData.append('Areas', area);
  });

  request.Timeslots.forEach(timeslot => {
    formData.append('Timeslots', timeslot);
  });

  const requestInit: RequestInit = {
    method: 'POST',
    body: formData,
  };

  return fetch(url, requestInit);
};

export default {
  subscribe,
  unsubscribe,
  subscribeDailyNewsletter,
};
